import React, { useState, useEffect } from "react";
// SUB SCREENS
import StemsAndOutcomes from "./StemsAndOutcomes";
import FinalMarks from "./FinalMarks";
import OBAScoresheet from "./ScoreSheetSummary";
// COMPONENTS
import Layout from "../Layout/Index";
import ConfirmationModal from "../Shared/ConfirmationModal";
import IconDrawer from "../Shared/IconsDrawer";
// UI Lib
import { Box, Stepper, Step, StepLabel, CircularProgress } from "@mui/material";
import { Button, Row, Col } from "react-bootstrap";
// API Calls
import { stemsOutcomeAction } from "../Store/Actions/stemsOutcomesAction";
import { updateFinalMarkSheetAction } from "../Store/Actions/finalMarkSheetAction";
// Modals
import { IFinalMarkSheet, IScoresheet } from "../Models/IScoresheet";
// Navigation
import { useNavigate, useParams } from "react-router";
// Constants
import { CourseID, StudentID } from "../Utilities/Constants";

const steps = [
  "Stems & Outcomes",
  "Scoresheets & Outcomes Summary",
  "Final Marks",
];

export default function OBASteps() {
  const { studentId, courseId } = useParams();

  const [activeStep, setActiveStep] = useState<number>();
  const [isCourseCompleted, setIsCourseCompleted] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [outcomes, setOutcomes] = useState<any[]>([]);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [scoreSheetList, setScoreSheetList] = useState<IScoresheet[]>([]);
  const [rerender, setRerender] = useState<boolean>(false);
  const [finalMarksheet, setFinalMarksheet] = useState<IFinalMarkSheet>();

  const [studentName, setStudentName] = useState<string>("");
  const [courseName, setCourseName] = useState<string>("");
  const [grade, setGrade] = useState<string>("");
  const [stemsOutcomeList, setStemsOutcomeList] = useState<[]>([]);
  const [searchLoading, setSearchLoading] = useState(true);
  const [searchResultError, setSearchResultError] = useState("");

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };
  const handleTriggerRerender = () => setRerender(!rerender);

  const handleUpdateOverallComment = (comments: string) => {
    handleTriggerRerender();
  };

  const breadcrumb: any = [
    { title: "OBA", link: `/oba/${studentId}/${courseId}` },
  ];

  const navigate = useNavigate();
  const downloadProgressReport = () => {
    // window.location.replace("/report");
    navigate(`/report/${studentId}/${courseId}`);
  };

  const handleGetStems = (step: any) => {
    setSearchLoading(true);
    if (courseId && courseId !== "0" && studentId && studentId !== "0") {
      stemsOutcomeAction(
        { studentId, courseId, activeStep: step },
        setStemsOutcomeList,
        setScoreSheetList,
        setFinalMarksheet,
        setCourseName,
        setStudentName,
        setGrade,
        setOutcomes,
        setSearchLoading,
        setSearchResultError,
        setActiveStep
      );
    }
  };

  const handleNext = () => {
    if (activeStep === 1) {
      setShowModal(true);
      return;
    }
    if (activeStep === steps.length - 1) {
      downloadProgressReport();
      return;
    }
    let newSkipped = skipped;
    if (isStepSkipped(activeStep ?? 0)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep ?? 0);
    }
    handleGetStems(activeStep ? activeStep + 1 : 1);
    setSkipped(newSkipped);
  };

  const handleCompleteCourse = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep ?? 0)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep ?? 0);
    }
    const newStep = activeStep ? activeStep + 1 : 1;
    if (finalMarksheet)
      updateFinalMarkSheetAction({
        item: {
          ...finalMarksheet,
          courseComeplete: true,
          currentStep: newStep,
        },
        callback: handleGetStems,
      });
    setSkipped(newSkipped);
    setShowModal(false);
    setIsCourseCompleted(true);
  };

  const handleBack = () => {
    handleGetStems(activeStep ? activeStep - 1 : 0);
  };

  const nextButtonText = () => {
    if (activeStep === steps.length - 1) return "Generate Report Card";
    if (activeStep === 1) return "Course Complete?";
    return "Next";
  };

  useEffect(() => {
    if (activeStep === undefined) handleGetStems(activeStep);
  }, [activeStep]);

  useEffect(() => {
    if (courseId && courseId !== "0" && studentId && studentId !== "0") {
      localStorage.setItem(CourseID, courseId);
      localStorage.setItem(StudentID, studentId);
    }
  }, [courseId, studentId]);

  return (
    <Layout
      breadcrumList={breadcrumb}
      pageTitle="Outcome Based Assessment"
      IconNo="2"
    >
      {courseId && courseId !== "0" && studentId && studentId !== "0" ? (
        <>
          <ConfirmationModal
            show={showModal}
            handleClose={() => setShowModal(false)}
            heading="Please Confirm"
            text="Are you sure you want to mark this course as completed?"
            buttonText="Yes"
            button1Function={handleCompleteCourse}
          />
          {searchLoading ? (
            <div className="Loader">
              <CircularProgress></CircularProgress>
            </div>
          ) : (
            <Box sx={{ width: "100%" }}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: {
                    optional?: React.ReactNode;
                  } = {};
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>

              <Row className="mt-4">
                <Col className="col-lg-6 d-flex align-items-center">
                  <p style={{ fontSize: "18px", color: "grey" }}>
                    Student Name:
                  </p>
                  <p style={{ fontSize: "16px", fontWeight: 700 }}>
                    &nbsp;&nbsp;{studentName}
                  </p>
                </Col>
                <Col className="col-lg-6 d-flex align-items-center">
                  <p style={{ fontSize: "18px", color: "grey" }}>Grade:</p>
                  <p style={{ fontSize: "16px", fontWeight: 700 }}>
                    &nbsp;&nbsp;{grade}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="col-lg-12 fs-6 colorBlue">
                  Course: {courseName}
                </Col>
                {isCourseCompleted && activeStep === 2 ? (
                  <Col className="col-lg-12">
                    <i className="d-flex">
                      <IconDrawer
                        type="15"
                        className="fs-4 cursorPointer colorBlue"
                      />
                      <p className="fs-6 colorGreen mx-2">
                        This Course: {courseName} has been finalised and is no
                        longer avaliable for grading for {studentName}
                      </p>
                    </i>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
              {activeStep === 0 ? (
                <StemsAndOutcomes
                  courseId={courseId ?? "1"}
                  outcomes={outcomes}
                  studentId={studentId ?? "1"}
                  data={stemsOutcomeList}
                  setData={setStemsOutcomeList}
                  handleTriggerRerender={handleTriggerRerender}
                  setLoading={setSearchLoading}
                />
              ) : activeStep === 1 && finalMarksheet ? (
                <OBAScoresheet
                  stemsList={stemsOutcomeList}
                  scoreSheetList={scoreSheetList}
                  finalMarksheet={finalMarksheet}
                  setScoresheetList={setScoreSheetList}
                  setFinalMarksheet={setFinalMarksheet}
                  handleTriggerRerender={handleTriggerRerender}
                  setLoading={setSearchLoading}
                />
              ) : activeStep === 2 && finalMarksheet ? (
                <FinalMarks
                  handleUpdateComment={handleUpdateOverallComment}
                  finalMarksheet={finalMarksheet}
                />
              ) : (
                <div className="Loader">
                  <CircularProgress></CircularProgress>
                </div>
              )}
              <Box sx={{ display: "flex", flexDirection: "row", pt: 3 }}>
                {/* {isCourseCompleted ? (
                  <></>
                ) : ( */}
                <Button disabled={activeStep === 0} onClick={handleBack}>
                  Back
                </Button>
                {/* )} */}

                <Box sx={{ flex: "1 1 auto" }} />

                {activeStep === 1 ? (
                  <Button onClick={downloadProgressReport}>
                    Progress Report
                  </Button>
                ) : (
                  <></>
                )}

                <Box sx={{ flex: "1 1 auto" }} />

                <Button onClick={handleNext}>{nextButtonText()}</Button>
              </Box>
            </Box>
          )}
        </>
      ) : studentId && studentId !== "0" ? (
        "No course found"
      ) : (
        "No student found"
      )}
    </Layout>
  );
}
