import { useEffect, useState } from "react";
import Layout from "../Layout/Index";
import { IStudent } from "../Models/IStudents";
import {
  ProfiencyLocalStorage,
  searchParameters,
} from "../Utilities/Constants";
import Spinner from "react-bootstrap/Spinner";
import { Card, Container, Button, Table } from "react-bootstrap";
import { IProficiencyScale } from "../Models/IScoresheet";

const ProficiencyScales = () => {
  // BREADCRUMB FOR LAYOUT
  const breadcrumb: any = [
    { title: "Proficiency Scale", link: "/proficiencyScale" },
  ];

  const [proficiencyList, setProficiencyList] = useState<IProficiencyScale[]>(
    []
  );

  useEffect(() => {
    let optionsList = localStorage.getItem(ProfiencyLocalStorage);
    if (optionsList != null) {
      var items = JSON.parse(optionsList);
      setProficiencyList(items);
    }
  }, []);

  return (
    <Layout
      breadcrumList={breadcrumb}
      pageTitle="Proficiency Scales"
      IconNo="2"
    >
      <Container className="my-2">
        <Card>
          <Card.Header className="bgBlue50 colorNavy">
            Proficiency Scales List
          </Card.Header>
          {proficiencyList?.length ? (
            <Table bordered hover>
              <thead>
                <tr>
                  <th>Grade</th>
                  <th>Description</th>
                  <th>Cutoff %</th>
                  <th>Grade Value</th>
                </tr>
              </thead>
              <tbody>
                {proficiencyList.map((proficiency: any, id: any) => (
                  <tr key={proficiency.id}>
                    <td>{proficiency.grade}</td>
                    <td>{proficiency.description}</td>
                    <td>{proficiency.cutoff}</td>
                    <td>{proficiency.gradeValue}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <></>
          )}
        </Card>
      </Container>
    </Layout>
  );
};

export default ProficiencyScales;
