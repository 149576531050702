import { useState } from "react";
import { Container, Table, Row, Col, Button } from "react-bootstrap";
import IconDrawer from "../../Shared/IconsDrawer";
import AddModal from "./AddStems&OutcomeModal";
import ConfirmationModal from "../../Shared/ConfirmationModal";
import { AddStudentFormAttributes } from "../../Utilities/OBAUtilities";
import { IStemsOutcomeList } from "../../Models/IScoresheet";
import {
  deleteAssignmentAction,
  saveAssignmentAction,
} from "../../Store/Actions/stemsOutcomesAction";
import { StudentID } from "../../Utilities/Constants";
import { handleGetCommentText } from "../../Utilities/StringUtilities";

interface PROPS {
  data: IStemsOutcomeList[];
  setData: any;
  outcomes: any[];
  handleTriggerRerender: any;
  setLoading: any;
  courseId: string;
  studentId: string;
}

const OBAScoresheet = ({
  data,
  setData,
  outcomes,
  handleTriggerRerender,
  setLoading,
  courseId,
  studentId,
}: PROPS) => {
  let initialScoreSheet: IStemsOutcomeList = {
    assignment: "",
    outcome1Id: 0,
    proficiency1Id: 0,
    proficiency1Enabled: true,
    outcome2Id: 0,
    proficiency2Id: 0,
    proficiency2Enabled: true,
    outcome3Id: 0,
    proficiency3Id: 0,
    proficiency3Enabled: true,
    outcome4Id: 0,
    proficiency4Id: 0,
    proficiency4Enabled: true,
    comments: "",
    studentId: 0,
    courseId: 0,
    id: 0,
    proficiencyScale1: null,
    outcomes1: null,
    proficiencyScale2: null,
    outcomes2: null,
    proficiencyScale3: null,
    outcomes3: null,
    proficiencyScale4: null,
    outcomes4: null,
    createdApp: "",
    createdAt: new Date(),
    createdBy: "",
    updatedApp: "",
    updatedAt: new Date(),
    updatedBy: "",
    version: "",
  };

  const [showAddModal, setSHowAddModal] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [editIndex, setEditIndex] = useState<number>(0);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [rerender, setRerender] = useState<boolean>(false);
  // ERROR MESSAGES FOR ALL FIELDS WILL BE STORED HERE
  const [validations, setValidations] = useState<any>({});
  const [confirmationText, setConfirmationText] = useState("");
  const [newAssignment, setNewAssignment] =
    useState<IStemsOutcomeList>(initialScoreSheet);

  const handleCloseAddModal = (clearAssignment = true) => {
    setSHowAddModal(false);
    setValidations({});
    if (clearAssignment) setNewAssignment(initialScoreSheet);
    handleTriggerRerender();
    setIsEdit(false);
  };
  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
    setSHowAddModal(false);
    setValidations({});
    setNewAssignment(initialScoreSheet);
    handleTriggerRerender();
    setIsEdit(false);
  };
  const handleOpenAddModal = () => setSHowAddModal(true);
  const handleOpenConfirmationModal = () => setShowConfirmationModal(true);

  // GENERIC FUNCTION TO UPDATE VALUE AGAINT BINDING KEY IN NEW STUDENT
  const handleChangeValue = (
    e: any,
    name: keyof IStemsOutcomeList,
    value?: any
  ) => {
    let newValue = value ?? e?.target?.value;
    if (name === "assignment")
      setNewAssignment((oldVal) => ({
        ...oldVal,
        assignment: newValue.toString(),
      }));
    if (name === "outcome1Id")
      setNewAssignment((oldVal) => ({
        ...oldVal,
        outcome1Id: parseInt(newValue),
      }));
    if (name === "proficiency1Id")
      setNewAssignment((oldVal) => ({
        ...oldVal,
        proficiency1Id: parseInt(newValue),
      }));
    if (name === "proficiency1Enabled")
      setNewAssignment((oldVal) => ({
        ...oldVal,
        proficiency1Enabled: Boolean(newValue),
      }));
    if (name === "outcome2Id")
      setNewAssignment((oldVal) => ({
        ...oldVal,
        outcome2Id: parseInt(newValue),
      }));
    if (name === "proficiency2Id")
      setNewAssignment((oldVal) => ({
        ...oldVal,
        proficiency2Id: parseInt(newValue),
      }));
    if (name === "proficiency2Enabled")
      setNewAssignment((oldVal) => ({
        ...oldVal,
        proficiency2Enabled: Boolean(newValue),
      }));
    if (name === "outcome3Id")
      setNewAssignment((oldVal) => ({
        ...oldVal,
        outcome3Id: parseInt(newValue),
      }));
    if (name === "proficiency3Id")
      setNewAssignment((oldVal) => ({
        ...oldVal,
        proficiency3Id: parseInt(newValue),
      }));
    if (name === "proficiency3Enabled")
      setNewAssignment((oldVal) => ({
        ...oldVal,
        proficiency3Enabled: Boolean(newValue),
      }));
    if (name === "outcome4Id")
      setNewAssignment((oldVal) => ({
        ...oldVal,
        outcome4Id: parseInt(newValue),
      }));
    if (name === "proficiency4Id")
      setNewAssignment((oldVal) => ({
        ...oldVal,
        proficiency4Id: parseInt(newValue),
      }));
    if (name === "proficiency4Enabled")
      setNewAssignment((oldVal) => ({
        ...oldVal,
        proficiency4Enabled: Boolean(newValue),
      }));
    if (name === "comments")
      setNewAssignment((oldVal) => ({
        ...oldVal,
        comments: newValue.toString(),
      }));
    // IF USER HAD ENTERED SOME VALUE, REMOVE VALIDATION FOR THAT FIELD
    // if (temp[name]) setValidations({ ...validations, [name]: "" });
    // // TRIGGER RERENDER
    // handleTriggerRerender();
  };

  const handleReturnDDVal = (name: keyof IStemsOutcomeList, options: [any]) => {
    if (!newAssignment[name]) return "Select";
    const selectedOpt = options.find((opt) => opt.value == newAssignment[name]);
    return selectedOpt?.option ? selectedOpt.option : newAssignment[name];
  };

  const handlePerformAction = () => {
    debugger;
    if (
      confirmationText ===
      "Are you sure you want to edit the selected assignment?"
    ) {
      let allRecords = data;
      allRecords[editIndex] = newAssignment;
      setLoading(true);
      saveAssignmentAction(
        courseId,
        studentId,
        newAssignment,
        setData,
        setLoading
      );
    } else if (
      confirmationText === "Are you sure you want to add a new assignment?"
    ) {
      setLoading(true);
      saveAssignmentAction(
        courseId,
        studentId,
        newAssignment,
        setData,
        setLoading
      );
    } else if (
      confirmationText ===
      "Are you sure you want to delete the selected assignment?"
    ) {
      let allRecords = data;
      let item = allRecords[editIndex];
      setLoading(true);
      deleteAssignmentAction(item.id, setData, setLoading);
    }
    setRerender(!rerender);
    handleCloseConfirmationModal();
  };

  const handleCheckIfBothOutcomeAndProfAreSelected = (
    missingField: [string],
    tempValidation: any
  ) => {
    const message = "Both Outcome and Proficiency must be selected";
    if (
      (newAssignment.outcome2Id && !newAssignment.proficiency2Id) ||
      (!newAssignment.outcome2Id && newAssignment.proficiency2Id)
    ) {
      missingField.push("outcome2Id");
      missingField.push("proficiency2Id");
      tempValidation.outcome2Id = message;
      tempValidation.proficiency2Id = message;
    }
    if (
      (newAssignment.outcome3Id && !newAssignment.proficiency3Id) ||
      (!newAssignment.outcome3Id && newAssignment.proficiency3Id)
    ) {
      missingField.push("outcome3Id");
      missingField.push("proficiency3Id");
      tempValidation.outcome3Id = message;
      tempValidation.proficiency3Id = message;
    }
    if (
      (newAssignment.outcome4Id && !newAssignment.proficiency4Id) ||
      (!newAssignment.outcome4Id && newAssignment.proficiency4Id)
    ) {
      missingField.push("outcome4Id");
      missingField.push("proficiency4Id");
      tempValidation.outcome4Id = message;
      tempValidation.proficiency4Id = message;
    }
  };

  const handleAddAssignment = () => {
    // ALL MISSING FIELDS WILL BE PUSHED IN THIS ARRAY
    let missingField: any = [];
    // ADDING ERROR MESSAGES AGAINST EACH FORM INPUT
    let tempValidation: any = {};
    AddStudentFormAttributes.forEach((assignment: any) => {
      if (
        !newAssignment[assignment.name as keyof IStemsOutcomeList] &&
        assignment.required &&
        newAssignment[assignment.name as keyof IStemsOutcomeList] !== false
      ) {
        missingField.push(assignment.name);
        tempValidation[assignment.name] = `${assignment.label} is required.`;
      }
    });
    handleCheckIfBothOutcomeAndProfAreSelected(missingField, tempValidation);
    // SETTING ERRORS
    setValidations(tempValidation);
    // RETURN IF VALIDATION FAILS
    if (missingField.length) return;

    if (isEdit) {
      setConfirmationText(
        "Are you sure you want to edit the selected assignment?"
      );
    } else {
      setConfirmationText("Are you sure you want to add a new assignment?");
    }
    handleCloseAddModal(false);
    handleOpenConfirmationModal();
  };

  const handleEditRecord = (index: number) => {
    setNewAssignment(JSON.parse(JSON.stringify(data[index])));
    setEditIndex(index);
    setRerender(!rerender);
    setIsEdit(true);
    handleOpenAddModal();
  };

  const handleDeleteRecordFromList = (index: number) => {
    setConfirmationText(
      "Are you sure you want to delete the selected assignment?"
    );
    setEditIndex(index);
    handleOpenConfirmationModal();
  };

  const handleUpdateCheckbox = (e: any, index: any) => {
    let temp = data;
    let name: keyof IStemsOutcomeList = e.target.name;
    if (name === "proficiency1Enabled")
      temp[index].proficiency1Enabled = Boolean(e.target.checked);
    else if (name === "proficiency2Enabled")
      temp[index].proficiency2Enabled = Boolean(e.target.checked);
    else if (name === "proficiency3Enabled")
      temp[index].proficiency3Enabled = Boolean(e.target.checked);
    else if (name === "proficiency4Enabled")
      temp[index].proficiency4Enabled = Boolean(e.target.checked);
    setData(temp);
    handleTriggerRerender();
  };

  const spanStyling = {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 5px",
  };

  const handleGenerateConfirmationModalHeading = () => {
    if (
      confirmationText ===
      "Are you sure you want to delete the selected assignment?"
    )
      return "Delete confirmation";
    return "Please Confirm";
  };

  return (
    <Container className="my-4 px-0">
      <ConfirmationModal
        show={showConfirmationModal}
        handleClose={handleCloseConfirmationModal}
        heading={handleGenerateConfirmationModalHeading()}
        text={confirmationText}
        buttonText="Yes"
        button1Function={handlePerformAction}
      />
      <AddModal
        show={showAddModal}
        handleClose={handleCloseAddModal}
        outcomes={outcomes}
        data={data}
        setData={setData}
        rerender={handleTriggerRerender}
        newAssignment={newAssignment}
        validations={validations}
        handleChangeValue={handleChangeValue}
        handleReturnDDVal={handleReturnDDVal}
        handleAddAssignment={handleAddAssignment}
        isEdit={isEdit}
      />

      <Row className="my-4">
        <Col className="col-lg-12">
          <i className="d-flex">
            <IconDrawer type="15" className="fs-4 cursorPointer colorBlue" />
            <p className="fs-6 colorRed mx-2">
              This course is enabled for Outcome Based Assessment, enter the
              assignments below for grading.
            </p>
          </i>
        </Col>
        <Col className="col-lg-10"></Col>
        <Col className="col-lg-2">
          <Button
            onClick={() => handleOpenAddModal()}
            variant="primary"
            className="w-100"
          >
            Add Assignment
          </Button>
        </Col>
      </Row>
      <Table bordered hover>
        <thead>
          <tr>
            <th>Assignment</th>
            <th>Outcome</th>
            <th>Proficiency </th>
            <th>Outcome</th>
            <th>Proficiency </th>
            <th>Outcome</th>
            <th>Proficiency </th>
            <th>Outcome</th>
            <th>Proficiency </th>
            <th>Comments</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item: any, index: number) => (
            <tr key={index}>
              <td>{item.assignment}</td>
              <td>{item.outcomes1 ? item.outcomes1.outcome : ""}</td>
              <td>
                <span style={spanStyling}>
                  {item.proficiencyScale1 ? (
                    <>
                      {item.proficiencyScale1.grade}
                      <input
                        name="proficiency1Enabled"
                        type="checkbox"
                        disabled={true}
                        checked={item.proficiency1Enabled}
                        onChange={(e) => handleUpdateCheckbox(e, index)}
                      />{" "}
                    </>
                  ) : (
                    ""
                  )}{" "}
                </span>
              </td>
              <td>{item.outcomes2 ? item.outcomes2.outcome : ""}</td>

              <td>
                <span style={spanStyling}>
                  {item.proficiencyScale2 ? (
                    <>
                      {item.proficiencyScale2.grade}
                      <input
                        name="proficiency2Enabled"
                        type="checkbox"
                        disabled={true}
                        checked={item.proficiency2Enabled}
                        onChange={(e) => handleUpdateCheckbox(e, index)}
                      />
                    </>
                  ) : (
                    ""
                  )}{" "}
                </span>
              </td>
              <td>{item.outcomes3 ? item.outcomes3.outcome : ""}</td>
              <td>
                <span style={spanStyling}>
                  {item.proficiencyScale3 ? (
                    <>
                      {item.proficiencyScale3.grade}
                      <input
                        name="proficiency3Enabled"
                        type="checkbox"
                        disabled={true}
                        checked={item.proficiency3Enabled}
                        onChange={(e) => handleUpdateCheckbox(e, index)}
                      />
                    </>
                  ) : (
                    ""
                  )}{" "}
                </span>
              </td>
              <td>{item.outcomes4 ? item.outcomes4.outcome : ""}</td>
              <td>
                <span style={spanStyling}>
                  {item.proficiencyScale4 ? (
                    <>
                      {item.proficiencyScale4.grade}
                      <input
                        name="proficiency4Enabled"
                        type="checkbox"
                        disabled={true}
                        checked={item.proficiency4Enabled}
                        onChange={(e) => handleUpdateCheckbox(e, index)}
                      />{" "}
                    </>
                  ) : (
                    ""
                  )}{" "}
                </span>
              </td>
              <td style={{ maxWidth: 240 }}>
                {handleGetCommentText(item.comments)}
              </td>
              <td>
                <span className="d-flex">
                  <span onClick={() => handleEditRecord(index)}>
                    <IconDrawer type="11" className="fs-5 mx-1 cursorPointer" />
                  </span>
                  <span onClick={() => handleDeleteRecordFromList(index)}>
                    <IconDrawer type="12" className="fs-5 mx-1 cursorPointer" />
                  </span>
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default OBAScoresheet;
