// RETURN STRING WITH ADDITIONAL TEXT IF STRING IS NOT EMPTY
export const returnStringWithText = ({ str, text }: any) => {
  return str ? text + str : "";
};

export const handleGetCommentText = (text: any, length = 80) => {
  if (!text) return "";
  if (text.length > length) {
    return text.slice(0, length - 3) + "...";
  }
  return text;
};
