import React, { useState, useEffect } from "react";
// imported for routing
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// SCREENS
import Login from "./Login";
import ProficiencyScales from "./ProficiencyScales";
import Outcomes from "./Outcomes";
import Report from "./ReportCard";
import OBA from "./OBA";
// MSAL imports
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
// Config for MS Graph API
import { callMsGraph } from "./Config/graph";
// Config for MS Login API
import { loginRequest } from "./Config/AzureLogin";
// Constants
import {
  UserProfileLocalStorage,
  TokenSessionStorage,
  CourseID,
  StudentID,
} from "./Utilities/Constants";
import {
  getCourseAction,
  getProfiencyAction,
} from "./Store/Actions/preloadAction";
import { reactPlugin } from "./Config/AppInsights";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";

// THIS APP IS USING MSAL TO LOG USER IN
// MSAL PROVIDES 2 COMPONENTS FOR AUTHENTICATED AND UNAUTHENTICATED USERS
// BASIC CONFIGURATION IS PRESENT IN THE CONFIG FOLDER
// THE APP IS WRAPPED IN MSAL PROVIDER IN THE index.tsx FILE

function App() {
  // MSAL's Hook is returning accounts and instance
  const { instance, accounts } = useMsal();
  const [isTokenReceived, setIsTokenReceived] = useState<boolean>(false);
  const [obaLink, setObaLink] = useState<string>("/oba");

  // Calling the Get Token API
  const getToken = (request: any) => {
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setIsTokenReceived(true);
        sessionStorage.setItem(TokenSessionStorage, response.accessToken);
      })
      .catch((error) => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(request).then((response) => {
            setIsTokenReceived(true);
            sessionStorage.setItem(TokenSessionStorage, response.accessToken);
          });
        }
      });
  };

  const getGraphData = (request: any) => {
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        callMsGraph(response.accessToken).then((response: any) =>
          // Saving response of graph API in LS
          localStorage.setItem(
            UserProfileLocalStorage,
            JSON.stringify(response ?? {})
          )
        );
      })
      .catch((e) => {
        instance
          .acquireTokenPopup({
            ...loginRequest,
            account: accounts[0],
          })
          .then((response: any) => {
            callMsGraph(response.accessToken).then((response: any) =>
              // Saving response of graph API in LS
              localStorage.setItem(
                UserProfileLocalStorage,
                JSON.stringify(response ?? {})
              )
            );
          });
      });
  };

  useEffect(() => {
    // Calling MS Graph API & && token API if user is authemticated
    if (accounts.length) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      getToken(request);
      getGraphData(request);
    }
  }, [accounts, instance]);

  useEffect(() => {
    getProfiencyAction();
    getCourseAction();
  }, [isTokenReceived]);

  useEffect(() => {
    let link = "/oba";
    const studentId = localStorage.getItem(StudentID);
    const courseId = localStorage.getItem(CourseID);
    if (studentId && courseId) setObaLink(`/oba/${studentId}/${courseId}`);
    else setObaLink(link);
  }, []);

  return (
    <AppInsightsErrorBoundary
      onError={() => <h1>I believe something went wrong</h1>}
      appInsights={reactPlugin}
    >
      <>
        {/* ALL ROUTES WILL BE ACCESSIBLE ONLY WHEN USER IS AUTHENTICATED */}
        <AuthenticatedTemplate>
          <BrowserRouter>
            <Routes>
              <Route path="/oba/:studentId/:courseId" element={<OBA />} />
              <Route path="/oba" element={<OBA />} />
              {/* <Route path="/oba/" element={<OBA />} /> */}
              <Route path="/proficiency" element={<ProficiencyScales />} />
              <Route path="/outcomes" element={<Outcomes />} />
              <Route path="/report/:studentId/:courseId" element={<Report />} />
              {/* HOME IS SET AS THE DEFAULT ROUTE FOR THE AUTHENTICATED USER */}
              <Route
                path="*"
                element={<Navigate to={obaLink} replace={true} />}
              />
            </Routes>
          </BrowserRouter>
        </AuthenticatedTemplate>
        {/* ONLY LOGIN PAGE WILL BE VISIBLE FOR THE UNAUTHENTICATED USER AND THAT WOULD ALSO BE THE DEFAULT PAGE */}
        <UnauthenticatedTemplate>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="*" element={<Navigate to="/" replace={true} />} />
              <Route path="/oba/:studentId/:courseId" element={<Login />} />
            </Routes>
          </BrowserRouter>
        </UnauthenticatedTemplate>
      </>
    </AppInsightsErrorBoundary>
  );
}

export default App;
