// BASE URL OF THE APP
//export const url = "https://StarsChoicesdevha.azurewebsites.net/";
export const url = process.env.REACT_APP_baseUrl;
//export const url = "https://localhost:7283/";

// SWAGGER
// https://StarsChoicesdevha.azurewebsites.net/swagger/index.html

// URLs FOR DIFFERENT ROUTES

export const stemsOutcomesApi = url + "api/StemsOutcomesAssignments/";

export const scoreSheetSummaryApi = url + "api/ScoreSheetSummary/";

export const finalMarkSheetApi = url + "api/FinalMark/";

export const getProfiencyApi = url + "api/ProficiencyScale";

export const getOutcomeApi = url + "api/Outcomes/?courseId=";

export const getCourseApi = url + "api/v1/Courses/";

export default url;
