import { useEffect, useState } from "react";
import Layout from "../Layout/Index";
// import {
//   OutcomeLocalStorage,
//   coursesDDMLocalStorage,
//   searchParameters,
// } from "../Utilities/Constants";
import Spinner from "react-bootstrap/Spinner";
import {
  Card,
  Container,
  Button,
  Table,
  Row,
  Col,
  Dropdown,
  Form,
} from "react-bootstrap";
import { IOutcome } from "../Models/IScoresheet";
// import { getOutcomeAction } from "../Store/Actions/preloadAction";
import {
  getCourseList,
  getOutcomeAction,
} from "../Store/Actions/stemsOutcomesAction";
import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
//import Autocomplete from "react-autocomplete";

interface ICourseOption {
  option: string;
  value: string;
}
const Outcomes = () => {
  // BREADCRUMB FOR LAYOUT
  const breadcrumb: any = [{ title: "Outcomes", link: "/outcomes" }];

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 200,
  });

  const [SelectedCourse, setSelectedCourse] = useState<ICourseOption | null>(
    null
  );
  const [SelectedCourseName, setSelectedCourseName] = useState<string | null>(
    ""
  );
  const [Courselist, setCourseList] = useState<ICourseOption[]>([]);
  const [screenLoader, setScreenLoader] = useState<boolean>(true);
  const [Outcomelist, setOutcomeList] = useState<IOutcome[]>([]);
  const [outcomeSectionLoader, setOutcomeSectionLoader] =
    useState<boolean>(false);

  const handleSelectCourse = (event: any, course: any) => {
    if (!course) {
      setSelectedCourseName("");
      setSelectedCourse(null);
      return;
    }
    setSelectedCourseName(course.option);
    setSelectedCourse(course);
  };

  useEffect(() => {
    getCourseList(setCourseList, setScreenLoader);
  }, []);
  useEffect(() => {
    if (SelectedCourse && SelectedCourse.value) {
      setOutcomeList([]);
      setOutcomeSectionLoader(true);
      getOutcomeAction(
        SelectedCourse.value,
        setOutcomeList,
        setOutcomeSectionLoader
      );
    } else setOutcomeList([]);
  }, [SelectedCourse]);

  return (
    <Layout breadcrumList={breadcrumb} pageTitle="Outcomes" IconNo="2">
      <Container className="my-2">
        {!screenLoader ? (
          <Card>
            <Card.Header className="bgBlue50 colorNavy">Outcomes</Card.Header>
            <Row className="mx-1">
              <Autocomplete
                filterOptions={filterOptions}
                options={Courselist}
                renderOption={(props: any, option: any) => (
                  <div {...props} key={option?.value}>
                    {option?.option.trim() ?? ""}
                  </div>
                )}
                getOptionLabel={(option: any) => option?.option.trim() ?? ""}
                sx={{ width: 300, margin: "20px 0 20px auto" }}
                value={SelectedCourse}
                onChange={handleSelectCourse}
                renderInput={(params) => (
                  <TextField {...params} label="Course" />
                )}
              />
            </Row>
            <Row className="mx-3">
              {Outcomelist?.length ? (
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th>Theme</th>
                      <th>Outcomes</th>
                      <th>Synthesized Outcomes</th>
                      <th>Weight</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Outcomelist.map((item: any, id: any) => (
                      <tr key={item.value}>
                        <td>{item.theme}</td>
                        <td>{item.outcome}</td>
                        <td>{item.description}</td>
                        <td>{item.weight}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : outcomeSectionLoader ? (
                <div className="Loader">
                  <Spinner />
                </div>
              ) : SelectedCourse ? (
                <Row className="p-2 fs-5">
                  No outcomes available for this course.
                </Row>
              ) : (
                <></>
              )}
            </Row>
          </Card>
        ) : (
          <div className="Loader">
            <Spinner />
          </div>
        )}
      </Container>
    </Layout>
  );
};

export default Outcomes;
