import React, { useState, useEffect } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import ConfirmationModal from "../../Shared/ConfirmationModal";
import { IFinalMarkSheet } from "../../Models/IScoresheet";
import { saveCommentAction } from "../../Store/Actions/finalMarkSheetAction";
import IconDrawer from "../../Shared/IconsDrawer";

interface PROPS {
  finalMarksheet: IFinalMarkSheet;
  handleUpdateComment: any;
}
const FinalMarks = ({ finalMarksheet, handleUpdateComment }: PROPS) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const [commentStatus, setCommentStatus] = useState<boolean>(false);

  const handleSaveComment = () => {
    handleUpdateComment(comment);
    setShowModal(false);
    saveCommentAction(
      { ...finalMarksheet, comments: comment },
      setCommentStatus
    );
  };

  useEffect(() => {
    const changeSlider = setTimeout(() => {
      setCommentStatus(false);
    }, 10000);
    return () => clearTimeout(changeSlider);
  }, [commentStatus]);

  useEffect(() => {
    setComment(finalMarksheet.comments);
  }, []);

  return (
    <Container className="my-4">
      <ConfirmationModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        heading="Please Confirm"
        text="Are you sure you want to save this comment?"
        buttonText="Yes"
        button1Function={handleSaveComment}
      />
      <Form>
        <Row>
          <Col className="col-lg-2 mt-3 fs-5">Course Marks:</Col>
          <Col className="col-lg-4 mt-3 fs-5">{finalMarksheet.finalMarks}%</Col>
          <Col className="col-lg-6"></Col>
          <Col className="col-lg-2 mt-3 fs-5">Overall Comments:</Col>
          <Col className="col-lg-4 mt-3 fs-5">
            <Row>
              <Col className="col-lg-12">
                <Form.Control
                  type="text"
                  as="textarea"
                  placeholder="Enter Comment"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </Col>
            </Row>
            {commentStatus ? (
              <Row>
                <Col className="col-lg-12 fs-6 my-2">
                  <IconDrawer type="17" className="colorGreen mx-1 mb-1" />
                  Comments saved successfully.
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <Row>
              <Col className="col-lg-12 mt-2 d-flex justify-content-end">
                <Button variant="primary" onClick={() => setShowModal(true)}>
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default FinalMarks;
