import React from "react";
import Navbar from "react-bootstrap/Navbar";

const Footer = () => {
  return (
    <Navbar
      bg="dark"
      fixed="bottom"
      className="d-flex justify-content-between px-3"
    >
      <Navbar.Brand>
        <Navbar.Text className="colorBlue50 px-2">Version: 1.00</Navbar.Text>
        <Navbar.Text className="colorBlue50 px-2">
          {/* DISPLAYING CURRENT YEAR */}
          Copyright {new Date().getFullYear()}
        </Navbar.Text>
      </Navbar.Brand>
      <Navbar.Brand>
        <Navbar.Text className="colorBlue50">Get in Touch:</Navbar.Text>
        <Navbar.Text className="colorWhite"> t.403.817.7777 |</Navbar.Text>
        <Navbar.Text className="colorWhite px-2">
          e.ISM SELF SERVICE PORTAL
        </Navbar.Text>
      </Navbar.Brand>
    </Navbar>
  );
};

export default Footer;
