import {
  OutcomeDDMLocalStorage,
  OutcomeLocalStorage,
  ProfiencyDDMLocalStorage,
  ProfiencyLocalStorage,
  coursesDDMLocalStorage,
} from "../../Utilities/Constants";
import { getCourseApi, getProfiencyApi } from "../Constants/BaseURL";
import axiosInstance from "../Constants/Instance";
import { trackException } from "../../Config/AppInsights";

export const getProfiencyAction = async () => {
  try {
    const { data } = await axiosInstance.get(getProfiencyApi);
    localStorage.setItem(
      ProfiencyLocalStorage,
      JSON.stringify(data.proficiencyScales)
    );
    localStorage.setItem(
      ProfiencyDDMLocalStorage,
      JSON.stringify(data.proficiencyScalesDDM)
    );
  } catch (e) {
    console.log(e);
    trackException(e);
  }
};

export const getCourseAction = async () => {
  try {
    const { data } = await axiosInstance.get(getCourseApi);

    localStorage.setItem(coursesDDMLocalStorage, JSON.stringify(data));
  } catch (e) {
    console.log(e);
    trackException(e);
  }
};
