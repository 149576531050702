import axios from "axios";
import * as App from "../../index";
import {
  UserProfileLocalStorage,
  TokenSessionStorage,
} from "../../Utilities/Constants";

const instance: any = axios.create({
  timeout: 15000,
});

instance.interceptors.request.use(
  function (config: any) {
    const instance = App.msalInstance;
    const accounts = instance.getAllAccounts();

    const accessTokenRequest: any = {
      scopes: [process.env.REACT_APP_scopes],
      account: accounts[0],
    };

    return instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse: any) => {
        // Acquire token silent success
        let accessToken = accessTokenResponse.accessToken;
        // Call your API with token
        config.headers.Authorization = `Bearer ${accessToken}`;
        return Promise.resolve(config);
      });
  },
  function (error: any) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response: any) => {
    if (response.status === 401) {
      const instance = App.msalInstance;
      localStorage.removeItem(UserProfileLocalStorage);
      sessionStorage.removeItem(TokenSessionStorage);
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
    return response;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

export default instance;
