import axiosInstance from "../Constants/Instance";
import {
  stemsOutcomesApi,
  getOutcomeApi,
  getCourseApi,
} from "../Constants/BaseURL";
import { IStemsOutcomeList } from "../../Models/IScoresheet";
import { trackException } from "../../Config/AppInsights";

// MANAGING THE LOADING AND ERROR STATES IN THE ACTION AS WELL
export const stemsOutcomeAction = async (
  { studentId, courseId, activeStep }: any,
  setStemsOutcomeList: any,
  setScoreSheetList: any,
  setFinalMarksheet: any,
  setCourseName: any,
  setStudentName: any,
  setGrade: any,
  setOutcomes: any,
  setLoading: any,
  setResultError: any,
  setActiveStep: any
) => {
  try {
    const newStep = !isNaN(activeStep) ? activeStep : "";
    let url =
      stemsOutcomesApi +
      "?StudentId=" +
      studentId +
      "&CourseId=" +
      courseId +
      "&step=" +
      newStep;
    const { data } = await axiosInstance.get(url);
    if (data) {
      loadData(
        data,
        setStemsOutcomeList,
        setScoreSheetList,
        setFinalMarksheet,
        setCourseName,
        setGrade,
        setStudentName,
        setOutcomes,
        setActiveStep
      );
      // if (
      //   data.stemsOutcomeList &&
      //   Array.isArray(data.stemsOutcomeList) &&
      //   data.stemsOutcomeList.length
      // ) {
      //   setStemsOutcomeList(data.stemsOutcomeList);
      // }

      // if (
      //   data.scoresheet &&
      //   Array.isArray(data.scoresheet) &&
      //   data.scoresheet.length
      // ) {
      //   setScoreSheetList(data.scoresheet);
      // }

      // setFinalMarksheet(data.finalMarkSheet);

      // setCourseName(data.course.courseName);
      // setStudentName(
      //   data.student.sirsCFirstName + " " + data.student.sirsCLastName
      // );
      // setGrade(5);
    } else setResultError("NoItem");
    setLoading(false);
  } catch (e) {
    console.log(e);
    setLoading(false);
    trackException(e);
    setResultError("Something went wrong. Please try again later");
  }
};

const loadData = (
  data: any,
  setStemsOutcomeList: any,
  setScoreSheetList: any,
  setFinalMarksheet: any,
  setCourseName: any,
  setGrade: any,
  setStudentName: any,
  setOutcomes: any,
  setActiveStep: any
) => {
  if (
    data.stemsOutcomeList &&
    Array.isArray(data.stemsOutcomeList) &&
    data.stemsOutcomeList.length
  ) {
    setStemsOutcomeList(data.stemsOutcomeList);
  }

  if (
    data.scoresheet &&
    Array.isArray(data.scoresheet) &&
    data.scoresheet.length
  ) {
    setScoreSheetList(data.scoresheet);
  }

  if (data.outcomes && Array.isArray(data.outcomes) && data.outcomes.length)
    setOutcomes(data.outcomes);

  setFinalMarksheet(data.finalMarkSheet);
  setActiveStep(data.finalMarkSheet.currentStep);
  setCourseName(data.course.courseName);
  setStudentName(
    data.student.sirsCFirstName + " " + data.student.sirsCLastName
  );
  setGrade(data.sirsStudent.sirsGrade);
};

const loadDataStems = (data: any, setData: any) => {
  if (
    data.stemsOutcomeList &&
    Array.isArray(data.stemsOutcomeList) &&
    data.stemsOutcomeList.length
  ) {
    setData(data.stemsOutcomeList);
  }
};

export const saveAssignmentAction = async (
  courseId: string,
  studentId: string,
  item: IStemsOutcomeList,
  setData: any,
  setLoading: any
) => {
  try {
    if (item.id == 0) {
      item.studentId = parseInt(studentId);
      item.courseId = parseInt(courseId);
      const { data } = await axiosInstance.post(stemsOutcomesApi, item);

      if (data) {
        loadDataStems(data, setData);
      }
    } else {
      const { data } = await axiosInstance.put(
        stemsOutcomesApi + item.id,
        item
      );
      if (data) {
        loadDataStems(data, setData);
      }
    }
    setLoading(false);
  } catch (e) {
    setLoading(false);
    console.log(e);
    trackException(e);
  }
};

export const deleteAssignmentAction = async (
  id: number,
  setData: any,
  setLoading: any
) => {
  try {
    const { data } = await axiosInstance.delete(stemsOutcomesApi + id);
    if (data) {
      loadDataStems(data, setData);
    }
    setLoading(false);
  } catch (e) {
    setLoading(false);
    console.log(e);
    trackException(e);
  }
};

export const getCourseList = async (setData: any, setLoading: any) => {
  try {
    const { data } = await axiosInstance.get(getCourseApi);
    // if (coursesList.length) setData(coursesList);
    if (data) setData(data);
    setLoading(false);
  } catch (e) {
    setLoading(false);
    console.log(e);
    trackException(e);
  }
};

export const getOutcomeAction = async (
  courseId: any,
  setList: any,
  setLoading: any
) => {
  try {
    const { data } = await axiosInstance.get(getOutcomeApi + courseId);
    setList(data.outcome);
    setLoading(false);
  } catch (e) {
    setLoading(false);
    trackException(e);
  }
};
