import axiosInstance from "../Constants/Instance";
import { scoreSheetSummaryApi, stemsOutcomesApi } from "../Constants/BaseURL";
import { IStemsOutcomeList } from "../../Models/IScoresheet";
import { trackException } from "../../Config/AppInsights";

export const saveMarksAction = async (
  item: any,
  setShowConfirmationModal: any,
  setSHowEditModal: any,
  setNewProficiency: any,
  handleTriggerRerender: any,
  setFinalMarksheet: any,
  setScoresheetList: any,
  setLoading: any
) => {
  try {
    setSHowEditModal(false);
    setLoading(true);
    const { data } = await axiosInstance.put(
      scoreSheetSummaryApi + item.id,
      item
    );

    setFinalMarksheet(data.finalMarkSheet);
    setScoresheetList(data.scoresheet);
    setShowConfirmationModal(false);

    setNewProficiency({});
    handleTriggerRerender();
    setLoading(false);
  } catch (e) {
    console.log(e);
    trackException(e);
  }
};
