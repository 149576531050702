import { Button, Card } from "react-bootstrap";
import Layout from "../Layout/Index";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../Config/AzureLogin";
import { useEffect } from "react";
import { CourseID, StudentID } from "../Utilities/Constants";
import { useParams } from "react-router";

const Login = () => {
  const { instance } = useMsal();
  const { studentId, courseId } = useParams();

  const handleLogin = (loginType: string) => {
    // LOGIN FUNCTION
    if (loginType === "redirect") {
      console.log(loginRequest);
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  };

  useEffect(() => {
    if (courseId && courseId !== "0" && studentId && studentId !== "0") {
      localStorage.setItem(CourseID, courseId);
      localStorage.setItem(StudentID, studentId);
    }
  }, [courseId, studentId]);

  return (
    <Layout isBreadCrumHidden={true} isOptionsHidden={true}>
      <Card className="p-5">
        <h3 className="text-center">
          Welcome to Calgary Board of Education's Star's Choices Admin site.
        </h3>
        <Card.Title className="text-center">
          <Button onClick={() => handleLogin("redirect")}>Sign In</Button>
        </Card.Title>
      </Card>
    </Layout>
  );
};

export default Login;
