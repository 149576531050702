import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  InputGroup,
  Dropdown,
} from "react-bootstrap";
import { AddStudentFormAttributes } from "../../Utilities/OBAUtilities";
import {
  OutcomeDDMLocalStorage,
  OutcomeLocalStorage,
  ProfiencyDDMLocalStorage,
  ProfiencyLocalStorage,
} from "../../Utilities/Constants";

const AddStemAndOutcomeModal = ({
  show,
  handleClose,
  newAssignment,
  outcomes,
  validations,
  handleChangeValue,
  handleReturnDDVal,
  handleAddAssignment,
  isEdit,
}: any) => {
  const [studentAttributes, setStudentAttributes] = useState<any>(
    AddStudentFormAttributes
  );

  useEffect(() => {
    const mappedOutcomes = outcomes.map((outcome: any) => ({
      option: outcome.outcome,
      value: outcome.id,
    }));
    handlePopulateOptions("outcome1Id", mappedOutcomes);
    handlePopulateOptions("outcome2Id", mappedOutcomes);
    handlePopulateOptions("outcome3Id", mappedOutcomes);
    handlePopulateOptions("outcome4Id", mappedOutcomes);

    handlePopulateOptionsFromLS("proficiency1Id", ProfiencyDDMLocalStorage);
    handlePopulateOptionsFromLS("proficiency2Id", ProfiencyDDMLocalStorage);
    handlePopulateOptionsFromLS("proficiency3Id", ProfiencyDDMLocalStorage);
    handlePopulateOptionsFromLS("proficiency4Id", ProfiencyDDMLocalStorage);
  }, []);

  const handlePopulateOptions = (name: any, items: any) => {
    try {
      var attributes = studentAttributes;
      var itemDW = attributes.find((p: any) => p.name === name);
      itemDW.options = items
        ? [{ option: "Select", value: "" }, ...items]
        : [{ option: "Select", value: "" }];
      setStudentAttributes(
        studentAttributes.map((attr: any) => {
          return attr.name === name ? itemDW : attr;
        })
      );
    } catch (error) {
      //debugger;
    }
  };

  const handlePopulateOptionsFromLS = (name: any, localStorageKey: any) => {
    let optionsList = localStorage.getItem(localStorageKey);
    if (optionsList != null) {
      var items = JSON.parse(optionsList);
    }
    handlePopulateOptions(name, items);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="lg"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? "Edit" : "Add"} Assignment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="p-2">
          <Row>
            {/* NAME */}
            <Col
              className={`col-sm-12 col-lg-${AddStudentFormAttributes[0].widthL} mb-2 px-0`}
            >
              <Form.Group className="mb-2" controlId="formBasicCBEID">
                <Form.Label>
                  {AddStudentFormAttributes[0].label}
                  {AddStudentFormAttributes[0].required ? " *" : ""}
                </Form.Label>
                <Form.Control
                  type={AddStudentFormAttributes[0].type}
                  placeholder={AddStudentFormAttributes[0].placeholder}
                  readOnly={AddStudentFormAttributes[0].readonly ?? false}
                  defaultValue={AddStudentFormAttributes[0].defaultVal}
                  value={newAssignment[AddStudentFormAttributes[0].name]}
                  // UPDATING BORDER COLOR IF THERE IS ANY ERROR AGAINST THIS FIELD
                  className={`${
                    validations[AddStudentFormAttributes[0].name]
                      ? "border-danger"
                      : ""
                  }`}
                  onChange={(event) =>
                    handleChangeValue(event, AddStudentFormAttributes[0].name)
                  }
                />
              </Form.Group>
              <Row className="px-3 text-danger">
                {validations[AddStudentFormAttributes[0].name]}
              </Row>
            </Col>
            <Col className={`col-sm-12 col-lg-4 mb-2 tableBorder py-1`}>
              <h6>Outcome *</h6>
              {/* OUTCOME 1 */}
              <Row className={`mb-2`}>
                <Dropdown className="w-100">
                  <Dropdown.Toggle
                    className="bgcolorBlue w-100"
                    id={AddStudentFormAttributes[1].name + "dropdown"}
                  >
                    {/* SHOWING SELECTED OPTION */}
                    {handleReturnDDVal(
                      AddStudentFormAttributes[1].name,
                      AddStudentFormAttributes[1].options
                    )}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="w-100">
                    {/* DISPLAYING OPTIONS FOR DD */}
                    {AddStudentFormAttributes[1].options.map(
                      (option: any, index: any) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() =>
                            handleChangeValue(
                              null,
                              AddStudentFormAttributes[1].name,
                              option.value
                            )
                          }
                          className={`px-3${
                            index % 2 === 0 ? " bgBlue10" : ""
                          }`}
                        >
                          {option.option}
                        </Dropdown.Item>
                      )
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                <div className="px-3 text-danger">
                  {validations[AddStudentFormAttributes[1].name]}
                </div>
              </Row>
              {/* OUTCOME 2 */}
              <Row className={`my-4`}>
                <Dropdown className="w-100">
                  <Dropdown.Toggle
                    className="bgcolorBlue w-100"
                    id={AddStudentFormAttributes[4].name + "dropdown"}
                  >
                    {/* SHOWING SELECTED OPTION */}
                    {handleReturnDDVal(
                      AddStudentFormAttributes[4].name,
                      AddStudentFormAttributes[4].options
                    )}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="w-100">
                    {/* DISPLAYING OPTIONS FOR DD */}
                    {AddStudentFormAttributes[4].options.map(
                      (option: any, index: any) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() =>
                            handleChangeValue(
                              null,
                              AddStudentFormAttributes[4].name,
                              option.value
                            )
                          }
                          className={`px-3${
                            index % 2 === 0 ? " bgBlue10" : ""
                          }`}
                        >
                          {option.option}
                        </Dropdown.Item>
                      )
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                <div className="px-3 text-danger">
                  {validations[AddStudentFormAttributes[4].name]}
                </div>
              </Row>
              {/* OUTCOME 3 */}
              <Row className={`my-4`}>
                <Dropdown className="w-100">
                  <Dropdown.Toggle
                    className="bgcolorBlue w-100"
                    id={AddStudentFormAttributes[7].name + "dropdown"}
                  >
                    {/* SHOWING SELECTED OPTION */}
                    {handleReturnDDVal(
                      AddStudentFormAttributes[7].name,
                      AddStudentFormAttributes[7].options
                    )}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="w-100">
                    {/* DISPLAYING OPTIONS FOR DD */}
                    {AddStudentFormAttributes[7].options.map(
                      (option: any, index: any) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() =>
                            handleChangeValue(
                              null,
                              AddStudentFormAttributes[7].name,
                              option.value
                            )
                          }
                          className={`px-3${
                            index % 2 === 0 ? " bgBlue10" : ""
                          }`}
                        >
                          {option.option}
                        </Dropdown.Item>
                      )
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                <div className="px-3 text-danger">
                  {validations[AddStudentFormAttributes[7].name]}
                </div>
              </Row>
              {/* OUTCOME 4 */}
              <Row className={`my-4`}>
                <Dropdown className="w-100">
                  <Dropdown.Toggle
                    className="bgcolorBlue w-100"
                    id={AddStudentFormAttributes[10].name + "dropdown"}
                  >
                    {/* SHOWING SELECTED OPTION */}
                    {handleReturnDDVal(
                      AddStudentFormAttributes[10].name,
                      AddStudentFormAttributes[10].options
                    )}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="w-100">
                    {/* DISPLAYING OPTIONS FOR DD */}
                    {AddStudentFormAttributes[10].options.map(
                      (option: any, index: any) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() =>
                            handleChangeValue(
                              null,
                              AddStudentFormAttributes[10].name,
                              option.value
                            )
                          }
                          className={`px-3${
                            index % 2 === 0 ? " bgBlue10" : ""
                          }`}
                        >
                          {option.option}
                        </Dropdown.Item>
                      )
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                <div className="px-3 text-danger">
                  {validations[AddStudentFormAttributes[10].name]}
                </div>
              </Row>
            </Col>
            <Col className={`col-sm-12 col-lg-8 mb-2 tableBorder py-1`}>
              <h6>Proficiency *</h6>
              {/* Proficiency 1 */}
              <Row className={`mb-2`}>
                <Col className={`col-sm-12 col-lg-6`}>
                  <Dropdown className="w-100">
                    <Dropdown.Toggle
                      className="bgcolorBlue w-100"
                      id={AddStudentFormAttributes[2].name + "dropdown"}
                    >
                      {/* SHOWING SELECTED OPTION */}
                      {handleReturnDDVal(
                        AddStudentFormAttributes[2].name,
                        AddStudentFormAttributes[2].options
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="w-100">
                      {/* DISPLAYING OPTIONS FOR DD */}
                      {AddStudentFormAttributes[2].options.map(
                        (option: any, index: any) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() =>
                              handleChangeValue(
                                null,
                                AddStudentFormAttributes[2].name,
                                option.value
                              )
                            }
                            className={`px-3${
                              index % 2 === 0 ? " bgBlue10" : ""
                            }`}
                          >
                            {option.option}
                          </Dropdown.Item>
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className="px-3 text-danger">
                    {validations[AddStudentFormAttributes[2].name]}
                  </div>
                </Col>
                <Col className={`col-sm-12 col-lg-6`}>
                  <InputGroup className="w-100">
                    <InputGroup.Text className="w-75">
                      Count in grade
                    </InputGroup.Text>
                    <InputGroup.Checkbox
                      checked={
                        newAssignment[AddStudentFormAttributes[3].name] ===
                        AddStudentFormAttributes[3].valTrue
                          ? true
                          : false
                      }
                      onChange={(event: any) =>
                        handleChangeValue(
                          null,
                          AddStudentFormAttributes[3].name,
                          event?.target?.checked
                            ? AddStudentFormAttributes[3].valTrue
                            : AddStudentFormAttributes[3].valFalse
                        )
                      }
                      aria-label="Checkbox for following text input"
                    />
                  </InputGroup>
                </Col>
              </Row>
              {/* Proficiency 2 */}
              <Row className={`my-4`}>
                <Col className={`col-sm-12 col-lg-6`}>
                  <Dropdown className="w-100">
                    <Dropdown.Toggle
                      className="bgcolorBlue w-100"
                      id={AddStudentFormAttributes[5].name + "dropdown"}
                    >
                      {/* SHOWING SELECTED OPTION */}
                      {handleReturnDDVal(
                        AddStudentFormAttributes[5].name,
                        AddStudentFormAttributes[5].options
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="w-100">
                      {/* DISPLAYING OPTIONS FOR DD */}
                      {AddStudentFormAttributes[5].options.map(
                        (option: any, index: any) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() =>
                              handleChangeValue(
                                null,
                                AddStudentFormAttributes[5].name,
                                option.value
                              )
                            }
                            className={`px-3${
                              index % 2 === 0 ? " bgBlue10" : ""
                            }`}
                          >
                            {option.option}
                          </Dropdown.Item>
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className="px-3 text-danger">
                    {validations[AddStudentFormAttributes[5].name]}
                  </div>
                </Col>
                <Col className={`col-sm-12 col-lg-6`}>
                  <InputGroup className="w-100">
                    <InputGroup.Text className="w-75">
                      Count in grade
                    </InputGroup.Text>
                    <InputGroup.Checkbox
                      checked={
                        newAssignment[AddStudentFormAttributes[6].name] ===
                        AddStudentFormAttributes[6].valTrue
                          ? true
                          : false
                      }
                      onChange={(event: any) =>
                        handleChangeValue(
                          null,
                          AddStudentFormAttributes[6].name,
                          event?.target?.checked
                            ? AddStudentFormAttributes[6].valTrue
                            : AddStudentFormAttributes[6].valFalse
                        )
                      }
                      aria-label="Checkbox for following text input"
                    />
                  </InputGroup>
                </Col>
              </Row>
              {/* Proficiency 3 */}
              <Row className={`my-4`}>
                <Col className={`col-sm-12 col-lg-6`}>
                  <Dropdown className="w-100">
                    <Dropdown.Toggle
                      className="bgcolorBlue w-100"
                      id={AddStudentFormAttributes[8].name + "dropdown"}
                    >
                      {/* SHOWING SELECTED OPTION */}
                      {handleReturnDDVal(
                        AddStudentFormAttributes[8].name,
                        AddStudentFormAttributes[8].options
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="w-100">
                      {/* DISPLAYING OPTIONS FOR DD */}
                      {AddStudentFormAttributes[8].options.map(
                        (option: any, index: any) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() =>
                              handleChangeValue(
                                null,
                                AddStudentFormAttributes[8].name,
                                option.value
                              )
                            }
                            className={`px-3${
                              index % 2 === 0 ? " bgBlue10" : ""
                            }`}
                          >
                            {option.option}
                          </Dropdown.Item>
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className="px-3 text-danger">
                    {validations[AddStudentFormAttributes[8].name]}
                  </div>
                </Col>
                <Col className={`col-sm-12 col-lg-6`}>
                  <InputGroup className="w-100">
                    <InputGroup.Text className="w-75">
                      Count in grade
                    </InputGroup.Text>
                    <InputGroup.Checkbox
                      checked={
                        newAssignment[AddStudentFormAttributes[9].name] ===
                        AddStudentFormAttributes[9].valTrue
                          ? true
                          : false
                      }
                      onChange={(event: any) =>
                        handleChangeValue(
                          null,
                          AddStudentFormAttributes[9].name,
                          event?.target?.checked
                            ? AddStudentFormAttributes[9].valTrue
                            : AddStudentFormAttributes[9].valFalse
                        )
                      }
                      aria-label="Checkbox for following text input"
                    />
                  </InputGroup>
                </Col>
              </Row>
              {/* Proficiency 4 */}
              <Row className={`my-4`}>
                <Col className={`col-sm-12 col-lg-6`}>
                  <Dropdown className="w-100">
                    <Dropdown.Toggle
                      className="bgcolorBlue w-100"
                      id={AddStudentFormAttributes[11].name + "dropdown"}
                    >
                      {/* SHOWING SELECTED OPTION */}
                      {handleReturnDDVal(
                        AddStudentFormAttributes[11].name,
                        AddStudentFormAttributes[11].options
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="w-100">
                      {/* DISPLAYING OPTIONS FOR DD */}
                      {AddStudentFormAttributes[11].options.map(
                        (option: any, index: any) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() =>
                              handleChangeValue(
                                null,
                                AddStudentFormAttributes[11].name,
                                option.value
                              )
                            }
                            className={`px-3${
                              index % 2 === 0 ? " bgBlue10" : ""
                            }`}
                          >
                            {option.option}
                          </Dropdown.Item>
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className="px-3 text-danger">
                    {validations[AddStudentFormAttributes[11].name]}
                  </div>
                </Col>
                <Col className={`col-sm-12 col-lg-6`}>
                  <InputGroup className="w-100">
                    <InputGroup.Text className="w-75">
                      Count in grade
                    </InputGroup.Text>
                    <InputGroup.Checkbox
                      checked={
                        newAssignment[AddStudentFormAttributes[12].name] ===
                        AddStudentFormAttributes[12].valTrue
                          ? true
                          : false
                      }
                      onChange={(event: any) =>
                        handleChangeValue(
                          null,
                          AddStudentFormAttributes[12].name,
                          event?.target?.checked
                            ? AddStudentFormAttributes[12].valTrue
                            : AddStudentFormAttributes[12].valFalse
                        )
                      }
                      aria-label="Checkbox for following text input"
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Col>
            {/* NAME */}
            <Col
              className={`col-sm-12 col-lg-${AddStudentFormAttributes[13].widthL} mb-2 px-0`}
            >
              <Form.Group className="mb-2" controlId="formBasicCBEID">
                <Form.Label>
                  {AddStudentFormAttributes[13].label}
                  {AddStudentFormAttributes[13].required ? " *" : ""}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  type={AddStudentFormAttributes[13].type}
                  placeholder={AddStudentFormAttributes[13].placeholder}
                  readOnly={AddStudentFormAttributes[13].readonly ?? false}
                  defaultValue={AddStudentFormAttributes[13].defaultVal}
                  value={newAssignment[AddStudentFormAttributes[13].name]}
                  // UPDATING BORDER COLOR IF THERE IS ANY ERROR AGAINST THIS FIELD
                  className={`${
                    validations[AddStudentFormAttributes[13].name]
                      ? "border-danger"
                      : ""
                  }`}
                  onChange={(event) =>
                    handleChangeValue(event, AddStudentFormAttributes[13].name)
                  }
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleAddAssignment}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddStemAndOutcomeModal;
