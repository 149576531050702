export const DummyData1 = [
  {
    assignment: "Assign 1",
    outcome1Id: 1,
    proficiency1Id: 1,
    proficiency1Enabled: true,
    outcome2Id: 2,
    proficiency2Id: 4,
    proficiency2Enabled: false,
    outcome3Id: 2,
    proficiency3Id: 6,
    proficiency3Enabled: true,
    outcome4Id: 3,
    proficiency4Id: 3,
    proficiency4Enabled: true,
    comments: "Test 1",
  },
  {
    assignment: "Assign 2",
    outcome1Id: 3,
    proficiency1Id: 2,
    proficiency1Enabled: true,
    outcome2Id: 4,
    proficiency2Id: 5,
    proficiency2Enabled: false,
    outcome3Id: 6,
    proficiency3Id: 2,
    proficiency3Enabled: true,
    outcome4Id: 2,
    proficiency4Id: 2,
    proficiency4Enabled: true,
    comments: "Test 2",
  },
];

export const DummyData2 = [
  {
    outcomeOverallId: 1,
    description:
      "Students will listen, speak, read, write, view and represent to explore thoughts, ideas, feelings and experiences",
    weightings: 12,
    mark: 50,
  },
  {
    outcomeOverallId: 2,
    description:
      "Students will listen, speak, read, write, view and represent to comprehend literature and other texts in oral, print, visual and multimedia forms, and respond personally, ethically and creatively",
    weightings: 35,
    mark: 73,
  },
  {
    outcomeOverallId: 3,
    description:
      "Students will listen, speak, read, write, view and represent to manage ideas and information",
    weightings: 12,
    mark: 95,
  },
  {
    outcomeOverallId: 4,
    description:
      "Students will listen, speak, read, write, view and represent create oral print, visual and multimedia text, and enhance the clarity and artistry of communication",
    weightings: 35,
    mark: 82,
  },
  {
    outcomeOverallId: 5,
    description:
      "Students will listen, speak, read, write, view and represent to respect, support and collaborate with others",
    weightings: 6,
    mark: 75,
  },
];

// FORM PARAMETERS FOR NEW Add Asignment
export const AddStudentFormAttributes: any = [
  {
    required: true,
    type: "text",
    widthL: "12",
    name: `assignment`,
    label: "Name",
    placeholder: "Enter Assignment Title",
  },
  {
    required: true,
    type: "Dropdown",
    widthL: "4",
    name: "outcome1Id",
    label: "Outcome",
    placeholder: "Enter Outcome",
    options: [],
  },
  {
    required: true,
    type: "Dropdown",
    widthL: "4",
    name: "proficiency1Id",
    label: "Proficiency",
    placeholder: "Enter Proficiency",
    options: [],
  },
  {
    required: false,
    type: "Checkbox",
    widthL: "4",
    valTrue: true,
    valFalse: false,
    value: true,
    name: "proficiency1Enabled",
    label: "Count in Grade:",
  },
  {
    required: false,
    type: "Dropdown",
    widthL: "4",
    name: "outcome2Id",
    label: "Outcome",
    placeholder: "Enter Outcome",
    options: [],
  },
  {
    required: false,
    type: "Dropdown",
    widthL: "4",
    name: "proficiency2Id",
    label: "Proficiency",
    placeholder: "Enter Proficiency",
    options: [],
  },
  {
    required: false,
    type: "Checkbox",
    widthL: "4",
    valTrue: true,
    valFalse: false,
    name: "proficiency2Enabled",
    label: "Count in Grade:",
  },
  {
    required: false,
    type: "Dropdown",
    widthL: "4",
    name: "outcome3Id",
    label: "Outcome",
    placeholder: "Enter Outcome",
    options: [],
  },
  {
    required: false,
    type: "Dropdown",
    widthL: "4",
    name: "proficiency3Id",
    label: "Proficiency",
    placeholder: "Enter Proficiency",
    options: [],
  },
  {
    required: false,
    type: "Checkbox",
    widthL: "4",
    valTrue: true,
    valFalse: false,
    name: "proficiency3Enabled",
    label: "Count in Grade:",
  },
  {
    required: false,
    type: "Dropdown",
    widthL: "4",
    name: "outcome4Id",
    label: "Outcome",
    placeholder: "Enter Outcome",
    options: [],
  },
  {
    required: false,
    type: "Dropdown",
    widthL: "4",
    name: "proficiency4Id",
    label: "Proficiency",
    placeholder: "Enter Proficiency",
    options: [],
  },
  {
    required: false,
    type: "Checkbox",
    widthL: "4",
    valTrue: true,
    valFalse: false,
    name: "proficiency4Enabled",
    label: "Count in Grade:",
  },
  {
    required: false,
    type: "text",
    widthL: "12",
    name: "comments",
    label: "Comments",
    placeholder: "Enter Comments",
  },
];

export const checkIfProfEnabled = (item: any) => {
  if (item.proficiency1Id && item.outcome1Id && item.proficiency1Enabled)
    return true;
  else if (item.proficiency2Id && item.outcome2Id && item.proficiency2Enabled)
    return true;
  else if (item.proficiency3Id && item.outcome3Id && item.proficiency3Enabled)
    return true;
  else if (item.proficiency4Id && item.outcome4Id && item.proficiency4Enabled)
    return true;
  return false;
};

export default AddStudentFormAttributes;
