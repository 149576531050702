// LOCALSTORAGE CONSTANTS
export const ProfiencyLocalStorage = "ProfiencyLocalStorage";
export const ProfiencyDDMLocalStorage = "ProfiencyDDMLocalStorage";

export const OutcomeLocalStorage = "OutcomeLocalStorage";
export const OutcomeDDMLocalStorage = "OutcomeDDMLocalStorage";

export const coursesDDMLocalStorage = "CourseDDMLocalStorage";

export const UserProfileLocalStorage = "UserProfileLocalStorage";
export const TokenSessionStorage = "TokenSessionStorage";

export const CourseID = "courseId";
export const StudentID = "studentId";

// HEADER OPTIONS
export const headerLinks = [
  { label: "OBA", link: "/none", iconNo: "2" },
  { label: "Proficiency Scale", link: "/proficiency", iconNo: "2" },
  { label: "Outcomes", link: "/outcomes", iconNo: "2" },
  { label: "Back to Choices Stars", link: "/home", iconNo: "6" },
  { label: "Help", link: "/home", iconNo: "11" },
];

// FORM PARAMETERS FOR WITHDRAWAL PAGE
export const searchWithdrawlParameters = [
  {
    required: false,
    Label: "CBE #",
    PlaceHolder: "Enter CBE #",
    Value: "cbeNo",
  },
  {
    required: false,
    Label: "ASN #",
    PlaceHolder: "Enter ASN #",
    Value: "asnNo",
  },
];

// FORM PARAMETERS FOR ENROLLMENT PAGE
export const searchParameters = [
  {
    Label: "First Name",
    required: false,
    PlaceHolder: "Enter First Name",
    Value: "firstName",
  },
  {
    Label: "Last Name",
    required: false,
    PlaceHolder: "Enter Last Name",
    Value: "lastName",
  },
  {
    required: false,
    Label: "CBE #",
    PlaceHolder: "Enter CBE #",
    Value: "cbeNo",
  },
  {
    required: false,
    Label: "ASN #",
    PlaceHolder: "Enter ASN #",
    Value: "asnNo",
  },
];
