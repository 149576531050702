import axiosInstance from "../Constants/Instance";
import { finalMarkSheetApi } from "../Constants/BaseURL";
import { trackException } from "../../Config/AppInsights";

export const saveMarksAction = async (
  item: any,
  setShowConfirmationModal: any,
  setSHowEditModal: any,
  setNewProficiency: any,
  handleTriggerRerender: any
) => {
  try {
    const { data } = await axiosInstance.put(finalMarkSheetApi + item.id, {
      item,
    });

    setShowConfirmationModal(false);
    setSHowEditModal(false);
    setNewProficiency({});
    handleTriggerRerender();
  } catch (e) {
    console.log(e);
    trackException(e);
  }
};

export const saveCommentAction = async (item: any, setCommentStatus: any) => {
  try {
    await axiosInstance.put(finalMarkSheetApi + item.id, {
      ...item,
    });
    setCommentStatus(true);
  } catch (e) {
    console.log(e);
    trackException(e);
  }
};

export const updateFinalMarkSheetAction = async ({
  item,
  setSearchLoading = () => {},
  callback = () => {},
}: {
  item: any;
  setSearchLoading?: any;
  callback?: any;
}) => {
  try {
    setSearchLoading(true);
    const { data } = await axiosInstance.put(finalMarkSheetApi + item.id, item);
    setSearchLoading(false);
    callback(item.currentStep);
  } catch (e) {
    console.log(e);
    trackException(e);
  }
};
