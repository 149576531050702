import React, { useEffect, useState } from "react";
import { Row, Col, Table, Button } from "react-bootstrap";
import { stemsOutcomeAction } from "../Store/Actions/stemsOutcomesAction";
import { IFinalMarkSheet, IScoresheet } from "../Models/IScoresheet";
import { Box, CircularProgress } from "@mui/material";
import Layout from "../Layout/Index";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useParams } from "react-router";
import { showLoader, hideLoader } from "../Utilities/loader";
import { handleShowCurrentDate } from "../Utilities/DateUtilities";
import { handleGetCommentText } from "../Utilities/StringUtilities";
import { checkIfProfEnabled } from "../Utilities/OBAUtilities";
// Constants
import { CourseID, StudentID } from "../Utilities/Constants";

const ReportCard = () => {
  const spanStyling = {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 5px",
  };

  const returnColWidth = (width: number) => {
    return { width: width + "%" };
  };

  const { courseId, studentId } = useParams();
  const [stemsOutcomeList, setStemsOutcomeList] = useState<[]>([]);
  const [searchLoading, setSearchLoading] = useState(true);
  const [searchResultError, setSearchResultError] = useState("");
  const [studentName, setStudentName] = useState<string>("");
  const [outcomes, setOutcomes] = useState<any[]>([]);
  const [courseName, setCourseName] = useState<string>("");
  const [grade, setGrade] = useState<string>("");
  const [scoreSheetList, setScoreSheetList] = useState<IScoresheet[]>([]);
  const [rerender, setRerender] = useState<boolean>(false);
  const [finalMarksheet, setFinalMarksheet] = useState<IFinalMarkSheet>({
    finalMarks: 0,
    comments: "",
    studentId: 0,
    courseComeplete: false,
    courseId: 0,
    id: 0,
    isFinalMarkEdited: false,
    isFinalMarkEditedBy: "",
    currentStep: 0,
    createdApp: "",
    createdAt: new Date(),
    createdBy: "",
    updatedApp: "",
    updatedAt: new Date(),
    updatedBy: "",
    version: "",
  });
  useEffect(() => {
    if (courseId && courseId !== "0" && studentId && studentId !== "0")
      stemsOutcomeAction(
        { studentId, courseId },
        setStemsOutcomeList,
        setScoreSheetList,
        setFinalMarksheet,
        setCourseName,
        setStudentName,
        setGrade,
        setOutcomes,
        setSearchLoading,
        setSearchResultError,
        setSearchResultError
      );
  }, [courseId]);

  const breadcrumb: any = [
    { title: "Report", link: `/report/${studentId}/${courseId}` },
  ];

  const downloadReport = () => {
    try {
      showLoader();
      const input: any = document.getElementById("reportCardDiv");
      html2canvas(input, { scale: 2 }).then((canvas: any) => {
        const imgData = canvas.toDataURL("image/jpg");
        const pdf = new jsPDF("portrait", "px", [1080, 1440]);
        pdf.addImage(imgData, "JPG", 50, 100, 980, 860, "NONE", "NONE");
        pdf.save("Report Card.pdf");
        hideLoader();
      });
    } catch (error: any) {
      hideLoader();
      console.log(error);
    }
  };

  useEffect(() => {
    if (courseId && courseId !== "0" && studentId && studentId !== "0") {
      localStorage.setItem(CourseID, courseId);
      localStorage.setItem(StudentID, studentId);
    }
  }, [courseId, studentId]);

  return (
    <Layout breadcrumList={breadcrumb} pageTitle="Report" IconNo="2">
      {!searchLoading ? (
        <>
          <Box sx={{ width: "100%" }}>
            <div className="my-4" id="reportCardDiv">
              <Row>
                <Col className="mt-1">
                  <img src="/CBELogo.png" alt="logo" />
                </Col>
                <Col className="mt-3">
                  <div className="fs-5 text-center">
                    <b>Discovering Choices</b>
                  </div>
                  <div className="fs-6 text-center">
                    <b>Student Progress Report with Teacher Comments</b>
                  </div>
                  <div className="fs-6 my-1 text-center">
                    between March 20, 2023 and April 19, 2023
                  </div>
                </Col>
                <Col>
                  <div className="fs-5 text-end">{handleShowCurrentDate()}</div>
                </Col>
              </Row>
              <Row className="my-4 mx-1">
                <Col className="col-lg-6 fs-5 border border-dark p-1 px-2">
                  <b>Course: {courseName}</b>
                </Col>
              </Row>
              <Row className="my-4 mx-1">
                <Col className="col-lg-4 fs-5 border border-dark p-1 px-2">
                  <b>Student: {studentName}</b>
                </Col>
              </Row>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Proficiency</th>
                    <th>Outcome</th>
                    <th>Proficiency </th>
                    <th>Outcomes</th>
                    <th>Proficiency </th>
                    <th>Outcome</th>
                    <th>Proficiency </th>
                    <th>Outcome</th>
                    <th>Proficiency </th>
                    <th>Comments</th>
                  </tr>
                </thead>
                <tbody>
                  {stemsOutcomeList
                    .filter((item) => checkIfProfEnabled(item))
                    .map((item: any, index: number) => (
                      <tr key={index}>
                        <td>{item.assignment}</td>
                        <td>{item.outcomes1 ? item.outcomes1.outcome : ""}</td>
                        <td>
                          <span style={spanStyling}>
                            {item.proficiencyScale1 ? (
                              <>
                                {item.proficiencyScale1.grade}
                                <input
                                  name="proficiency1Enabled"
                                  type="checkbox"
                                  disabled
                                  defaultChecked={item.proficiency1Enabled}
                                />
                              </>
                            ) : (
                              ""
                            )}{" "}
                          </span>
                        </td>
                        <td>{item.outcomes2 ? item.outcomes2.outcome : ""}</td>
                        <td>
                          <span style={spanStyling}>
                            {item.proficiencyScale2 ? (
                              <>
                                {item.proficiencyScale2.grade}
                                <input
                                  name="proficiency2Enabled"
                                  type="checkbox"
                                  disabled
                                  defaultChecked={item.proficiency2Enabled}
                                />
                              </>
                            ) : (
                              ""
                            )}{" "}
                          </span>
                        </td>
                        <td>{item.outcomes3 ? item.outcomes3.outcome : ""}</td>
                        <td>
                          <span style={spanStyling}>
                            {item.proficiencyScale3 ? (
                              <>
                                {item.proficiencyScale3.grade}
                                <input
                                  name="proficiency3Enabled"
                                  type="checkbox"
                                  disabled
                                  defaultChecked={item.proficiency3Enabled}
                                />
                              </>
                            ) : (
                              ""
                            )}{" "}
                          </span>
                        </td>
                        <td>{item.outcomes4 ? item.outcomes4.outcome : ""}</td>
                        <td>
                          <span style={spanStyling}>
                            {item.proficiencyScale4 ? (
                              <>
                                {item.proficiencyScale4.grade}
                                <input
                                  name="proficiency4Enabled"
                                  type="checkbox"
                                  disabled
                                  defaultChecked={item.proficiency4Enabled}
                                />
                              </>
                            ) : (
                              ""
                            )}{" "}
                          </span>
                        </td>
                        <td style={{ maxWidth: 240 }}>
                          {handleGetCommentText(item.comments)}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th style={returnColWidth(15)}>Outcome Overall</th>
                    <th style={returnColWidth(46)}>Description</th>
                    <th style={returnColWidth(13)}>Weightings</th>
                    <th style={returnColWidth(13)}>Mark %</th>
                  </tr>
                </thead>
                <tbody>
                  {scoreSheetList.map((item: IScoresheet, index: number) => (
                    <tr key={index}>
                      <td>
                        {item.outcomeOverall ? item.outcomeOverall.outcome : ""}
                      </td>
                      <td>{item.description}</td>
                      <td>{item.weightings ? item.weightings : "0"}%</td>
                      <td>{item.mark ? item.mark : "0"}%</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="p-1 mt-1 fs-5 border border-dark bgGrey">
                {`${
                  finalMarksheet.courseComeplete
                    ? "Final Marks:"
                    : "Marks to Date:"
                } ${finalMarksheet.finalMarks} %`}
              </div>
              <Row className="mt-2 p-1">
                <Col className="col-lg-3 fs-5">Comments:</Col>
                <Col className="col-lg-6 p-2 fs-5 border border-dark">
                  {finalMarksheet.comments}
                </Col>
                <Col className="col-lg-3"></Col>
              </Row>
            </div>
          </Box>
          <Box sx={{ width: "100%" }}>
            {" "}
            <Row>
              <Col className="offset-10 col-lg-2 mt-3">
                <Button
                  variant="primary"
                  onClick={() => {
                    downloadReport();
                  }}
                >
                  Download Report
                </Button>
              </Col>
            </Row>
          </Box>
        </>
      ) : (
        <div className="Loader">
          <CircularProgress></CircularProgress>
        </div>
      )}
    </Layout>
  );
};

export default React.memo(ReportCard);
