import { useState } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Modal,
  Form,
  Button,
} from "react-bootstrap";
import IconDrawer from "../../Shared/IconsDrawer";
import ConfirmationModal from "../../Shared/ConfirmationModal";
import {
  IFinalMarkSheet,
  IScoresheet,
  IStemsOutcomeList,
} from "../../Models/IScoresheet";
import { checkIfProfEnabled } from "../../Utilities/OBAUtilities";
import { saveMarksAction } from "../../Store/Actions/scoreSheetAction";
import { handleGetCommentText } from "../../Utilities/StringUtilities";

interface PROPS {
  stemsList: IStemsOutcomeList[];
  scoreSheetList: IScoresheet[];
  setScoresheetList: any;
  finalMarksheet: IFinalMarkSheet;
  setFinalMarksheet: any;
  handleTriggerRerender: any;
  setLoading: any;
}

const OBAScoresheet = ({
  stemsList,
  scoreSheetList,
  setScoresheetList,
  finalMarksheet,
  setFinalMarksheet,
  handleTriggerRerender,
  setLoading,
}: PROPS) => {
  const [showEditModal, setSHowEditModal] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [editIndex, setEditIndex] = useState<number>(0);

  const [confirmationText, setConfirmationText] = useState("");
  const [newProficiency, setNewProficiency] = useState<any>({});

  const handleCloseEditModal = () => {
    setSHowEditModal(false);
    handleTriggerRerender();
  };
  const handleCloseConfirmationModal = () => {
    saveMarksAction(
      newProficiency,
      setShowConfirmationModal,
      setSHowEditModal,
      setNewProficiency,
      handleTriggerRerender,
      setFinalMarksheet,
      setScoresheetList,
      setLoading
    );
  };
  const handleOpenEditModal = () => setSHowEditModal(true);
  const handleOpenConfirmationModal = () => setShowConfirmationModal(true);

  const handlePerformAction = () => {
    if (
      confirmationText === "Are you sure you want to update this assignment?"
    ) {
      let allRecords = scoreSheetList;
      allRecords[editIndex] = newProficiency;
      setScoresheetList(allRecords);
    } else if (
      confirmationText === "Are you sure you want to add this assignment?"
    ) {
    }
    handleTriggerRerender();
    handleCloseConfirmationModal();
  };

  // GENERIC FUNCTION TO UPDATE VALUE AGAINT BINDING KEY IN NEW STUDENT
  const handleChangeValue = (e: any, name: any, value?: any) => {
    let temp = newProficiency;
    let newValue = value ?? e?.target?.value;
    if (name === "mark" && newValue && parseInt(newValue) > 100) return;
    temp[name] = newValue;
    setNewProficiency(temp);
    // TRIGGER RERENDER
    handleTriggerRerender();
  };

  const handleUpdateMarks = () => {
    if (!newProficiency.mark) return;
    handleTriggerRerender();
    handleCloseEditModal();
    handleOpenConfirmationModal();
    setConfirmationText("Are you sure you want to update this assignment?");
  };

  const handleEditRecord = (index: number) => {
    setNewProficiency(JSON.parse(JSON.stringify(scoreSheetList[index])));
    setEditIndex(index);
    handleTriggerRerender();
    handleOpenEditModal();
  };

  const spanStyling = {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 5px",
  };

  const returnColWIdth = (width: number) => {
    return { width: width + "%" };
  };

  return (
    <Container className="my-4">
      <ConfirmationModal
        show={showConfirmationModal}
        handleClose={handleCloseConfirmationModal}
        heading="Please Confirm"
        text={confirmationText}
        buttonText="Yes"
        button1Function={handlePerformAction}
      />
      <Modal
        show={showEditModal}
        onHide={handleCloseEditModal}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Marks</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="p-2">
            <Row>
              <Col>
                <Form.Label>Current Marks(%)*</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Marks"
                  value={newProficiency.mark}
                  // UPDATING BORDER COLOR IF THERE IS ANY ERROR AGAINST THIS FIELD
                  className={`${!newProficiency.mark ? "border-danger" : ""}`}
                  onChange={(event) => handleChangeValue(event, "mark")}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {" "}
          <Button variant="primary" onClick={handleUpdateMarks}>
            Save
          </Button>
          <Button variant="danger" onClick={handleCloseEditModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <h5>
        <b>Scoresheet Summary</b>
      </h5>
      <Table bordered hover>
        <thead>
          <tr>
            <th>Proficiency</th>
            <th>Outcome</th>
            <th>Proficiency </th>
            <th>Outcomes</th>
            <th>Proficiency </th>
            <th>Outcome</th>
            <th>Proficiency </th>
            <th>Outcome</th>
            <th>Proficiency </th>
            <th>Comments</th>
          </tr>
        </thead>
        <tbody>
          {stemsList
            .filter((item: any) => checkIfProfEnabled(item))
            .map((item: any, index: number) => (
              <tr key={index}>
                <td>{item.assignment}</td>
                <td>{item.outcomes1 ? item.outcomes1.outcome : ""}</td>
                <td>
                  <span style={spanStyling}>
                    {item.proficiencyScale1 ? (
                      <>
                        {item.proficiencyScale1.grade}
                        <input
                          name="proficiency1Enabled"
                          type="checkbox"
                          disabled
                          readOnly
                          defaultChecked={item.proficiency1Enabled}
                        />
                      </>
                    ) : (
                      ""
                    )}{" "}
                  </span>
                </td>
                <td>{item.outcomes2 ? item.outcomes2.outcome : ""}</td>
                <td>
                  <span style={spanStyling}>
                    {item.proficiencyScale2 ? (
                      <>
                        {item.proficiencyScale2.grade}
                        <input
                          name="proficiency2Enabled"
                          type="checkbox"
                          disabled
                          readOnly
                          defaultChecked={item.proficiency2Enabled}
                        />
                      </>
                    ) : (
                      ""
                    )}{" "}
                  </span>
                </td>
                <td>{item.outcomes3 ? item.outcomes3.outcome : ""}</td>
                <td>
                  <span style={spanStyling}>
                    {item.proficiencyScale3 ? (
                      <>
                        {item.proficiencyScale3.grade}
                        <input
                          name="proficiency3Enabled"
                          type="checkbox"
                          disabled
                          readOnly
                          defaultChecked={item.proficiency3Enabled}
                        />
                      </>
                    ) : (
                      ""
                    )}{" "}
                  </span>
                </td>
                <td>{item.outcomes4 ? item.outcomes4.outcome : ""}</td>
                <td>
                  <span style={spanStyling}>
                    {item.proficiencyScale4 ? (
                      <>
                        {item.proficiencyScale4.grade}
                        <input
                          name="proficiency4Enabled"
                          type="checkbox"
                          disabled
                          readOnly
                          defaultChecked={item.proficiency4Enabled}
                        />
                      </>
                    ) : (
                      ""
                    )}{" "}
                  </span>
                </td>
                <td style={{ maxWidth: 240 }}>
                  {handleGetCommentText(item.comments)}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <h5>
        <b>Outcome Summary</b>
      </h5>
      <Table bordered hover>
        <thead>
          <tr>
            <th style={returnColWIdth(15)}>Outcome Overall</th>
            <th style={returnColWIdth(46)}>Description</th>
            <th style={returnColWIdth(13)}>Weightings</th>
            <th style={returnColWIdth(13)}>Mark %</th>
            <th style={returnColWIdth(13)}>Action</th>
          </tr>
        </thead>
        <tbody>
          {scoreSheetList.map((item: IScoresheet, index: number) => (
            <tr key={index}>
              <td>{item.outcomeOverall?.outcome}</td>
              <td>{item.description}</td>
              <td>{item.weightings ? item.weightings : "0"}%</td>
              <td>
                <span>{item.mark ? item.mark : "0"}% </span>
                {item.isMarkEdited ? (
                  <span title="Mark % has been updated">
                    <IconDrawer
                      type="15"
                      className="fs-6 mx-1 mb-1 cursorPointer"
                    />
                  </span>
                ) : (
                  <></>
                )}
              </td>
              <td>
                <span onClick={() => handleEditRecord(index)}>
                  <IconDrawer type="11" className="fs-5 mx-1 cursorPointer" />
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="p-1 mt-1 fs-5 border border-dark bgGrey">
        Marks to Date: {finalMarksheet.finalMarks} %
      </div>
    </Container>
  );
};

export default OBAScoresheet;
