// FUNCTION TO DETECT AND CONVERT JS DATE FORMAT TO YYYY-MM-DD FORMAT A
export const returnDate = (date: Date) => {
  return typeof date === "string" ? date : getYYYYMMDD(date);
};
// FUNCTION TO CONVERT JS DATE FORMAT TO YYYY-MM-DD FORMAT
export const getYYYYMMDD = (date: Date) => {
  if (!date) return;
  let month = date.getMonth() + 1;
  return `${date.getFullYear()}-${
    month < 10 ? "0" + month : month
  }-${date.getDate()}`;
};
// FUNCTION TO CALCULATE AGE FORMAT DATE
export const getAgeFromDOB = (date: Date) => {
  const today = new Date();
  let yearDiff = today.getFullYear() - date.getFullYear();
  let monthDiff = today.getMonth() - date.getMonth();
  let dayDiff = today.getDate() - date.getDate();
  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) return yearDiff - 1;
  return yearDiff;
};
// FUNCTION TO RETURN DATE FROM A DATE/TIME STRING
export const returnDateFromDateTimeStr = (date: string) => {
  if (!date) return "";
  let d = date.split("T");
  return d[0];
};

// FUNCTION TO RETURN CURRENT DATE WITH SPECIFIC FORMAT i.e Wednesday, June 19, 2023
export const handleShowCurrentDate = () => {
  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let date = new Date();
  return `${days[date.getDay()]}, ${
    months[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()}`;
};
