import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

const browserHistory: any = createBrowserHistory();
export const reactPlugin: any = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "d6686b3c-de2b-4489-8391-f2f8ff617360",
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();

export const trackException = (error: any) => {
  appInsights.trackException({ error: new Error(error), severityLevel: 3 });
};
